<template>
  <section>
    <!-- <b-card>
      <b-row>
        <b-col sm="12" md="4" lg="4">
          <v-select
            class="mb-1"
            v-model="filters.movie"
            :options="$store.state.master.movies"
            :filter-by="movieFilter"
            label="title"
            :reduce="(movie) => movie.movie_id"
            placeholder="Movie"
          >
            <template #selected-option="data">
              <span> {{ data.full_title }}</span>
            </template>
            <template #option="data">
              <div style="max-width: 100px !important">
                <span> {{ data.full_title }}</span>
              </div>
            </template>
          </v-select>
        </b-col>
        <b-col sm="12" md="4" lg="4">
          <v-select
            class="mb-1"
            v-model="filters.vendor"
            :options="$store.state.master.vendors"
            label="company"
            :reduce="(vendor) => vendor.vendor_id"
            placeholder="Customer"
          />
        </b-col>

        <b-col sm="6" md="2" lg="2">
          <b-button
            variant="primary"
            class="w-100 mb-1"
            @click="onFiltersChange"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            >Search</b-button
          >
        </b-col>
        <b-col sm="6" md="2" lg="2">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="w-100 mb-1"
            @click="clearFilters"
            >Reset</b-button
          >
        </b-col>
      </b-row>
    </b-card> -->
    <b-card no-body>
      <b-table
        small
        bordered
        class="position-relative"
        primary-key="contract_id"
        responsive
        :empty-text="tableEmptyText"
        show-empty
        :items="contractsList"
        :fields="fields"
        hover
      >
        <!-- hover -->
        <!-- <template #cell(sr)="data">
          {{ data.index + 1 + (pagination.currentPage - 1) * pagination.limit }}
        </template> -->
        <template #cell(company)="data">
          <div style="width: max-content">
            <b-badge variant="light-success">
              {{ data.item.contract_nature
              }}{{ data.item.draft ? "(draft)" : "" }}</b-badge
            ><br />{{ data.item.company }}
          </div>
        </template>
        <template #cell(title)="data">
          <div style="width: max-content" v-html="data.value"></div>
        </template>
        <template #cell(territory)="data">
          <span>
            {{ data.value }}
          </span>
        </template>
        <template #cell(right)="data">
          <span>
            {{ data.value }}
          </span>
        </template>
        <template #cell(language)="data">
          <span>
            {{ data.value }}
          </span>
        </template>
        <template #cell(purchaseDate)="data">
          <b-badge variant="light-info"
            >Contract Date : {{ data.item.purchaseDate }} </b-badge
          ><br />
          <b-badge variant="light-success" class="mt-1">
            Start : {{ data.item.rStart }} </b-badge
          ><br />

          <b-badge variant="light-danger" class="mt-1">
            End : {{ data.item.rExpiry }}</b-badge
          >
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              variant="primary"
              size="sm"
              @click="setReportData(data.item)"
            >
              Preview Report
            </b-button>
            <br />
            <br />
            <h6>
              <b-badge variant="light-warning">
                {{
                  data.item.statementPeriod == "1"
                    ? "Monthly"
                    : data.item.statementPeriod == "2"
                    ? "Half Yearly"
                    : data.item.statementPeriod == "3"
                    ? "Quaterly"
                    : data.item.statementPeriod == "4"
                    ? "Yearly"
                    : ""
                }}</b-badge
              >
            </h6>
            <span v-if="data.item.lastReportCreatedDate" class="texted-muted"
              >last generated:{{
                moment(data.item.lastReportCreatedFromDate).format(
                  "DD/MM/YYYY"
                ) +
                " to " +
                moment(data.item.lastReportCreatedDate).format("DD/MM/YYYY")
              }}
            </span>

            <!-- { value: '1', label: 'Monthly' },
            { value: '3', label: 'Quaterly' },
            { value: '2', label: 'Half Yearly' },
            { value: '4', label: 'Yearly' } -->
          </div>
        </template>
        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>
      </b-table>

      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                :limit="15"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BBadge,
  BTable,
  BCol,
  BRow,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BPagination,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BModal,
  VBTooltip,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import * as RevenueServies from "@/apiServices/RevenueServies";
import moment from "moment";
import { contract_natures, labels, contract_statuses } from "@/utils/constants";
import Treeselect from "@riophae/vue-treeselect";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    BCard,
    BBadge,
    BTable,
    BCol,
    BRow,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BPagination,
    FeatherIcon,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BModal,

    vSelect,
    Treeselect,
    flatPickr,
  },

  props: {
    selectedMovieId: {
      type: Number,
    },
    selectedVendorId: {
      type: Number,
    },
  },

  mounted() {
    //console.log(this.selectedMovieId, "selectedMovieId");
  },
  data() {
    return {
      tableEmptyText: "LOADING...",
      moment: moment,
      contract_natures,
      contract_statuses,

      filters: {
        search: "",
        status: "",
        contract_nature: "",
        vendor: "",
        movie: "",
        // territories: [],
        // rights: [],
        // languages: [],
        from: "",
        to: "",
      },

      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
        perPageOptions: [10, 25, 50, 100, "All"],
      },
      fields: [
        {
          key: "actions",
          label: "Actions",
          stickyColumn: true,
          variant: "primary",
        },

        {
          key: "company",
          label: "Contact",
        },
        {
          key: "title",
          label: "Movie",
        },
        {
          key: "territory",
          label: "territory",
        },
        {
          key: "right",
          label: "rights",
        },
        {
          key: "language",
          label: "languages",
        },
        {
          key: "purchaseDate",
          label: "contract dates",
        },
        // {
        //   key: "rStart",
        //   label: "start",
        // },
        // {
        //   key: "rExpiry",
        //   label: "end date",
        // },
        {
          key: "language",
          label: "languages",
        },
        {
          key: "contract_id",
          label: "ContractID",
          // sortable:true,
        },
      ],
      contractsList: [],
      selectedContractId: null,
    };
  },
  methods: {
    setReportData(data) {
      this.$emit("setReportDataEmit", data);
      console.log(data); // someValue
    },
    movieFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return (
        (option.unique &&
          option.unique.toString().toLowerCase().indexOf(temp) > -1) ||
        (option.title && option.title.toLowerCase().indexOf(temp) > -1) ||
        (option.international_title &&
          option.international_title.toLowerCase().indexOf(temp) > -1)
      );
    },
    clearFilters() {
      this.filters = {
        search: "",
        status: "",
        contract_nature: "",
        vendor: this.selectedVendorId,
        movie: this.selectedMovieId,
        territories: [],
        rights: [],
        languages: [],
        from: "",
        to: "",
      };
      this.pagination = {
        ...this.pagination,
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      };
      this.onChange();
    },
    onFiltersChange() {
      this.pagination.currentPage = 1;
      this.onChange();
    },
    rowClicked(item, index, event) {
      this.$router.push(`/contracts/details/${item.contract_uuid}`);
    },
    editTransactions(contract_id) {
      this.selectedContractId = contract_id;
      this.$refs["editTransactionsModal"].show();
    },

    async getContractsList() {
      console.log(this.selectedMovieId);
      this.filters.movie = this.selectedMovieId;
      this.filters.vendor = this.selectedVendorId;
      const loader = this.$loading.show();

      try {
        const response =
          await RevenueServies.GetAllPendingRoyaltyReportContract({
            limit: this.pagination.limit,
            page: this.pagination.currentPage,
            ...this.filters,
          });

        this.contractsList = response.data.data.contracts;
        if (!isNaN(response.data.data.totalRows)) {
          this.pagination.totalRows = response.data.data.totalRows;
        }
        if (!this.contractsList.length)
          this.tableEmptyText = "NO RECORDS FOUND";
      } catch (error) {
        console.log("Error in getting Contracts List");
        this.tableEmptyText = "Error in getting contracts";
      } finally {
        loader.hide();
      }
    },
    onChange() {
      this.$nextTick(() => {
        this.getContractsList();
        // localStorage.setItem(
        //   "filtersAndPagination1",
        //   JSON.stringify({
        //     filters: this.filters,
        //     pagination: this.pagination,
        //   })
        // );
      });
    },
    loadContractList() {
      //   const filtersAndPagination1Str = localStorage.getItem(
      //     "filtersAndPagination1"
      //   );
      //   if (filtersAndPagination1Str) {
      //     const obj = JSON.parse(filtersAndPagination1Str);
      //     this.pagination = obj.pagination;
      //     this.filters = obj.filters;
      //     this.$forceUpdate();
      //   }
      this.getContractsList();
    },
  },
  mounted() {
    this.loadContractList();

    this.$store.dispatch("master/setVendorsMaster");
    this.$store.dispatch("master/setMoviesMaster");

    this.$store.dispatch("master/setLabelMaster", { key: labels.language });

    // Tree Data
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.rights_tree,
      master: labels.rights,
      sub_master: labels.sub_right,
    });
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.territory_tree,
      master: labels.territory,
      sub_master: labels.sub_territory,
    });
  },
};
</script>

<style></style>
